.root {
  @apply space-x-2;
  @apply text-muted;
  @apply text-center;
  @apply uppercase;

  @screen md {
    @apply text-left;
  }

  &Item {
    &:not(&:first-of-type):before {
      @apply pr-1;
      content: '•';
      vertical-align: middle;
      @apply text-body;
    }
  }
}
