.root {
  @apply py-1;
  @apply px-4;
  @apply rounded-xl;
  @apply uppercase;
  @apply text-caption;

  &Default {
    @apply bg-card-hover;
    @apply text-dark;
  }

  &Primary {
    @apply bg-theme-light;
    @apply text-light;
  }

  @screen md {
    @apply text-small;
  }
}
