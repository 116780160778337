.root {
  @apply w-full;
  @apply flex;
  @apply flex-col;
  @apply space-x-0;

  @screen md {
    @apply w-fit;
    @apply flex-row;
    @apply space-x-4;
  }

  &Button {
    @apply w-full;
    @apply bg-light;
    @apply bg-opacity-10;
    @apply my-2;
    @apply py-6;
    @apply px-0;
    @apply rounded-xl;

    &:hover {
      @apply bg-opacity-20;
    }

    @screen md {
      @apply w-fit;
      @apply my-0;
      @apply py-3;
      @apply px-8;
      @apply rounded;
    }
  }

  &NonBindingRequest {
    @apply bg-secondary-main;
    @apply text-secondary-highlight;
    @apply shadow-lg;
    @apply shadow-secondary-main/20;

    @apply order-2;
    @screen md {
      @apply order-none;
    }

    &:hover {
      @apply shadow-secondary-main/40;
      @apply bg-opacity-95;
    }

  }

  &BidingRequest {
    @apply bg-primary-main;
    @apply text-primary-highlight;
    @apply shadow-lg;
    @apply shadow-primary-main/20;

    @apply order-1;
    @screen md {
      @apply order-none;
    }

    &:hover {
      @apply shadow-primary-main/40;
      @apply bg-opacity-95;
    }
  }

  &DisabledButton {

    &:disabled {
      @apply bg-card-background; // This line overrides the background color
      @apply cursor-not-allowed; // This line adds a new style
      @apply text-muted;
      @apply text-opacity-40;
      @apply shadow-none;
    }

    &:hover {
      @apply bg-opacity-100; // Override hover styles if needed
    }
  }
}
