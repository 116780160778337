.root {
  @apply flex;
  @apply items-baseline;
  @apply justify-center;

  @screen md {
    @apply justify-start;
  }

  &Price {
    @apply font-medium;
    line-height: inherit;
  }

  &Description {
    @apply ml-2;
    @apply font-normal;
  }

  &PriceBreakDown {
    @apply w-full;
    @apply h-full;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
  }

  &PriceDiscountBreakDown {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply gap-1;
  }

  &PriceBreakDownRow {
    @apply flex;
    @apply flex-row;
    @apply justify-between;
  }

  &PriceBreakDownDiscountRow {
    @apply ml-1.5;
  }
}
