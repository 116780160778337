.root {
  @apply w-full;
  @apply flex;
  @apply flex-col;
  @apply rounded-2xl;
  @apply px-8;
  @apply py-6;
  @apply my-3;
  @apply bg-light;
  @apply border-2;
  @apply text-dark;
  @apply border-card-default;

  @screen md {
    @apply flex-row;
    @apply items-center;
    @apply my-0;
    @apply px-8;
    @apply py-8;
    @apply justify-between;
    @apply translate-y-1/2;
  }

  &IsValid {
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply shadow-xl;
    @apply shadow-muted/10;
    @apply border;
    @apply border-card-default;
  }

  &Wrapper {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply my-4;

    @screen md {
      @apply items-start;
      @apply my-0;
    }
  }
}
