.root {
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
    @apply space-x-6;
  }

  &Item {
    @apply w-full;
    @screen md {
      @apply w-1/2;
    }
  }

  &Margin {
    @apply mt-2;
    @screen md {
      @apply mt-0;
    }
  }
}
