.root {

  &Item {
    @apply w-full;
    @apply min-h-24;

  }

  &Error {
    @apply absolute;
    @apply transform;
    @apply top-5;
    @apply right-6;
    @apply bg-error;
    @apply text-light;
    @apply tracking-wider;
    @apply rounded-3xl;
    @apply px-2;
    @apply py-1;
  }

}
