.root {
  @apply absolute;
  @apply right-4;
  @apply top-3;

  a {
    @apply text-muted;
    &:hover {
      @apply text-theme-light;
    }
  }

  @screen md {
    @apply top-auto;
    @apply bottom-4;
    @apply right-4;
  }
}
