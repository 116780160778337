.root {
  @apply inline-flex;
  @apply items-center;
  @apply text-category;
  @apply w-full;

  &Content {
    @apply font-medium;
  }
}
