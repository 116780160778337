.root {
  @apply flex;
  @apply items-baseline;

  &Price {
    @apply font-medium;
  }

  &Description {
    @apply ml-2;
    @apply font-normal;
    &:before {
      @apply content-['/'];
      @apply mr-2;
    }
  }
}
