.root {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply flex-col;
  @apply select-none;
  @apply rounded-xl;
  @apply p-3;
  @apply font-medium;
  @apply text-muted;
  @apply bg-card-default;

  &Icon {
    @apply w-12;
    @apply h-12;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  &:hover {
    @apply bg-card-hover;
  }

  &:disabled {
    @apply border-2;
    @apply border-card-hover;
    @apply bg-light;
    @apply cursor-not-allowed;
  }

  &Row {
    @apply flex-row;
    @apply justify-start;
    @apply items-center;
  }

  &Center {
    @apply justify-center;
  }

  &Selected {
    @apply bg-gradient-to-r;
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply shadow-xl;
    @apply shadow-theme-main/20;
    @apply text-light;

    &:hover,
    &:disabled {
      @apply bg-gradient-to-r;
      @apply from-theme-gradient-from;
      @apply to-theme-gradient-to;
    }

    .icon,
    .label {
      @apply text-light;
    }
  }

  &HasError {
    @apply border-2;
    @apply border-error;

    &:focus {
      @apply border-error;
    }
  }

  @screen md {
    @apply p-4;
  }
}
