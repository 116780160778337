.root {
  @apply w-full;
  @apply min-h-24;
  @apply justify-center;

  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply flex-col;
  @apply select-none;
  @apply rounded-xl;
  @apply p-3;
  @apply font-medium;
  @apply text-muted;
  @apply bg-card-default;

  &:disabled {
    @apply border-2;
    @apply border-card-hover;
    @apply bg-light;
    @apply cursor-not-allowed;
  }

  &Selected {
    @apply bg-gradient-to-r;
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply shadow-xl;
    @apply shadow-theme-main/20;
    @apply text-light;

    &:hover,
    &:disabled {
      @apply bg-gradient-to-r;
      @apply from-theme-gradient-from;
      @apply to-theme-gradient-to;
    }

    .icon,
    .label {
      @apply text-light;
    }
  }

  @screen md {
    @apply p-4;
  }
}
