.root {
  @apply flex;
  @apply flex-col;
  @apply m-2;
  @apply rounded-xl;
  @apply bg-card-default;
  @apply md:min-h-card;

  &:hover {
    @apply bg-card-hover;
  }

  &ImageWrapper {
    height: 160px;
    @apply w-full;
    @apply md:w-fit;
  }

  img {
    @apply rounded-t-lg;
    object-fit: cover;
    width: 100%;
  }

  &ImageSkeleton {
    @apply rounded-t-lg;
    @apply w-full;
    line-height: initial;
  }

  &Selected {
    @apply bg-gradient-to-r;
    @apply from-theme-gradient-from;
    @apply to-theme-gradient-to;
    @apply shadow-theme-main/20;
    @apply shadow-xl;

    * {
      @apply text-light;
    }

    &:hover {
      @apply bg-theme-main;
    }
  }

  &Loading {
    @apply cursor-default;
    &:hover {
      @apply bg-card-default;
    }
  }

  @screen md {
    @apply flex-row;

    img {
      @apply rounded-l-lg;
      @apply rounded-tr-none;
      @apply rounded-br-none;
      @apply saturate-50;
      @apply backdrop-brightness-125;
      height: 160px;
      min-width: 128px;
      object-fit: cover;
      width: 128px;
    }

    @-moz-document url-prefix() {
      img {
        @apply backdrop-brightness-100;
      }
    }

    &ImageSkeleton {
      @apply rounded-l-lg;
      @apply rounded-tr-none;
      @apply rounded-br-none;

      height: 160px;
      line-height: 160px;
      width: 128px;
    }

    &Selected,
    &:hover {
      img {
        @apply saturate-100;
      }
    }
  }
}
