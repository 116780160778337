.root {
  @apply text-dark;
  @apply text-opacity-70;
  @apply text-body;

  p {
    @apply mb-4;
  }

  * {
    @apply leading-6;

    @screen md {
      max-width: 556px;
    }
  }

  img {
    @apply w-full;
  }

  i {
    @apply italic;
  }

  h3 {
    @apply text-dark;
    @apply text-opacity-100;
    @apply font-bold;
    @apply uppercase;
    @apply mb-2;
  }

  hr {
    @apply text-card-hover;
    @apply my-6;
    @apply w-1/12;
    @apply border;
  }

  ul {
    @apply list-disc;
    @apply ml-6;
    @apply flex;
    @apply flex-col;
    @apply gap-2;
    list-style-type: '◇\002003';

    li::marker {
      @apply text-theme-main;
    }
  }

  b,
  strong {
    @apply text-dark;
    @apply text-opacity-100;
    @apply font-medium;
  }

  &MinimizeOverviewContent {
    @apply relative;
    @apply max-h-36;
    @apply overflow-hidden;
    @apply px-0.5;
  }

  &MinimizeOverviewGradient {
    @apply relative;
    @apply h-24;
    @apply -mt-24;
    @apply px-0.5;
    background-image: linear-gradient(to bottom, transparent, theme('colors.background'));
  }
}
