.root {
  @apply relative;

  &Label {
    @apply absolute;
    @apply text-caption;
    @apply text-dark;
    @apply text-opacity-80;
    @apply uppercase;
    @apply duration-300;
    @apply translate-x-6;
    @apply top-2;
  }

  &Select {
    @apply inline-block;
    @apply appearance-none;
    @apply px-6;
    @apply pt-6;
    @apply pb-3;
    @apply w-full;
    @apply bg-transparent;
    @apply outline-theme-main;
    @apply ring-theme-main;
    @apply rounded-xl;
    @apply border-2;
    @apply border-card-hover;
  }

  &HasError > &Select {
    @apply border-error;
    &:focus {
      @apply border-error;
    }
  }

  &Error {
    @apply absolute;
    @apply transform;
    @apply top-5;
    @apply right-6;
    @apply bg-error;
    @apply text-light;
    @apply tracking-wider;
    @apply rounded-3xl;
    @apply px-2;
    @apply py-1;
  }
}
